export const saveToLocalStorage = (key, item) => {
    try {
        const storage = window.localStorage;
        storage.setItem(key, item);
    }
    catch (e) {
        console.error(e);
    }
};
export const loadFromLocalStorage = (key) => {
    try {
        const storage = window.localStorage;
        return storage.getItem(key);
    }
    catch (e) {
        console.error(e);
    }
    return null;
};
export const deleteFromLocalStorage = (key) => {
    try {
        const storage = window.localStorage;
        storage.removeItem(key);
    }
    catch (e) {
        console.error(e);
    }
};
