import __, { i18nTemplate, defaultNullableI18n } from '../helpers/i18n.js';
class ValidationError extends Error {
    constructor(error, fieldName, limit, zipcodeList) {
        super(error);
        this.error = error;
        this.fieldName = fieldName;
        this.limit = limit;
        this.zipcodeList = zipcodeList;
        this.toString = () => `Backend Error: ${this.fieldName}: ${this.error}`;
        this.toLocalizedString = () => i18nTemplate(this.error, { fieldName: this.fieldName, limit: this.limit || '', zipcodeList: JSON.stringify(this.zipcodeList) || '' })
            || defaultNullableI18n(this.error)
            || __('Server responded, but an error occured');
    }
}
export class ClientValidationError extends ValidationError {
    constructor() {
        super(...arguments);
        this.toString = () => this.toLocalizedString();
    }
}
export default ValidationError;
