import { CompanyTypes } from './Company.js';
export var AvailableNotificationSettingValues;
(function (AvailableNotificationSettingValues) {
    AvailableNotificationSettingValues["SMS"] = "sms";
    AvailableNotificationSettingValues["SMS_VONAGE"] = "sms_vonage";
    AvailableNotificationSettingValues["EMAIL"] = "email";
    AvailableNotificationSettingValues["OFF"] = "off";
})(AvailableNotificationSettingValues = AvailableNotificationSettingValues || (AvailableNotificationSettingValues = {}));
export const isSmsNotificationValue = (notificationSetting) => notificationSetting === AvailableNotificationSettingValues.SMS || notificationSetting === AvailableNotificationSettingValues.SMS_VONAGE;
export var AuthenticationMode;
(function (AuthenticationMode) {
    AuthenticationMode["SIGN_IN_CHANNEL"] = "SIGN_IN_MESSAGE_CHANNEL";
})(AuthenticationMode = AuthenticationMode || (AuthenticationMode = {}));
export var NotificationSettingTypes;
(function (NotificationSettingTypes) {
    NotificationSettingTypes["SND_NEW_JOB_DRIVER"] = "SND_NEW_JOB_DRIVER";
    NotificationSettingTypes["SND_NEW_JOB_DISPATCHER"] = "SND_NEW_JOB_DISPATCHER";
    NotificationSettingTypes["SND_JOB_ALLOCATION_DRIVER"] = "SND_JOB_ALLOCATION_DRIVER";
    NotificationSettingTypes["SND_JOB_ALLOCATION_DISPATCHER"] = "SND_JOB_ALLOCATION_DISPATCHER";
    NotificationSettingTypes["SND_JOB_CANCELLATION_DRIVER"] = "SND_JOB_CANCELLATION_DRIVER";
    NotificationSettingTypes["SND_JOB_CANCELLATION_DISPATCHER"] = "SND_JOB_CANCELLATION_DISPATCHER";
})(NotificationSettingTypes = NotificationSettingTypes || (NotificationSettingTypes = {}));
export const NotificationSettingFieldNames = [
    NotificationSettingTypes.SND_NEW_JOB_DRIVER,
    NotificationSettingTypes.SND_NEW_JOB_DISPATCHER,
    NotificationSettingTypes.SND_JOB_ALLOCATION_DRIVER,
    NotificationSettingTypes.SND_JOB_ALLOCATION_DISPATCHER,
    NotificationSettingTypes.SND_JOB_CANCELLATION_DRIVER,
    NotificationSettingTypes.SND_JOB_CANCELLATION_DISPATCHER,
];
export function isNotificationSettingValue(data) {
    const notificationSettingValue = data;
    return !!(data && (notificationSettingValue === AvailableNotificationSettingValues.EMAIL || notificationSettingValue === AvailableNotificationSettingValues.SMS || notificationSettingValue === AvailableNotificationSettingValues.OFF));
}
export const defaultNotificationSettingValues = {
    [CompanyTypes.EXT]: {
        [NotificationSettingTypes.SND_NEW_JOB_DRIVER]: AvailableNotificationSettingValues.OFF,
        [NotificationSettingTypes.SND_NEW_JOB_DISPATCHER]: AvailableNotificationSettingValues.SMS_VONAGE,
        [NotificationSettingTypes.SND_JOB_ALLOCATION_DRIVER]: AvailableNotificationSettingValues.SMS_VONAGE,
        [NotificationSettingTypes.SND_JOB_ALLOCATION_DISPATCHER]: AvailableNotificationSettingValues.OFF,
        [NotificationSettingTypes.SND_JOB_CANCELLATION_DRIVER]: AvailableNotificationSettingValues.SMS_VONAGE,
        [NotificationSettingTypes.SND_JOB_CANCELLATION_DISPATCHER]: AvailableNotificationSettingValues.OFF,
    },
    [CompanyTypes.ADAC]: {
        [NotificationSettingTypes.SND_NEW_JOB_DRIVER]: AvailableNotificationSettingValues.OFF,
        [NotificationSettingTypes.SND_NEW_JOB_DISPATCHER]: AvailableNotificationSettingValues.EMAIL,
        [NotificationSettingTypes.SND_JOB_ALLOCATION_DRIVER]: AvailableNotificationSettingValues.SMS_VONAGE,
        [NotificationSettingTypes.SND_JOB_ALLOCATION_DISPATCHER]: AvailableNotificationSettingValues.EMAIL,
        [NotificationSettingTypes.SND_JOB_CANCELLATION_DRIVER]: AvailableNotificationSettingValues.SMS_VONAGE,
        [NotificationSettingTypes.SND_JOB_CANCELLATION_DISPATCHER]: AvailableNotificationSettingValues.EMAIL,
    },
};
export const activeNotificationSettings = [
    AvailableNotificationSettingValues.EMAIL,
    AvailableNotificationSettingValues.SMS_VONAGE,
];
export const activeNotificationSettingsWithTwilio = [
    ...activeNotificationSettings,
    AvailableNotificationSettingValues.SMS,
];
export const getAllNotificationSettingValues = (aditionalSettingValues) => ([
    ...aditionalSettingValues,
    AvailableNotificationSettingValues.OFF,
]);
export function getAllowedNotificationSettingValue(companyType, settingsName, settingValueString) {
    switch (settingValueString) {
        case AvailableNotificationSettingValues.EMAIL: return AvailableNotificationSettingValues.EMAIL;
        case AvailableNotificationSettingValues.SMS: return AvailableNotificationSettingValues.SMS;
        case AvailableNotificationSettingValues.SMS_VONAGE: return AvailableNotificationSettingValues.SMS_VONAGE;
        default: return defaultNotificationSettingValues[companyType][settingsName];
    }
}
