import { composeFuncs } from './compose.js';
import { scrubObject } from './scrub.js';
const BREADCRUMB_LIMIT = 64;
const filterBreadCrumbs = (crumb) => {
    if (crumb.data) {
        const newCrumb = Object.assign({}, crumb);
        const newData = Object.assign({}, newCrumb.data);
        Object.keys(newData).forEach((key) => {
            const value = newData[key];
            if (typeof value === 'string' && value.length > BREADCRUMB_LIMIT) {
                newData[key] = `${value.substring(0, BREADCRUMB_LIMIT)}...`;
            }
        });
        newCrumb.data = newData;
        return newCrumb;
    }
    return crumb;
};
export const limitBreadCrumbs = (event) => {
    var _a;
    const breadcrumbs = (_a = event.breadcrumbs) !== null && _a !== void 0 ? _a : [];
    const newBreadcrumbs = breadcrumbs.filter(filterBreadCrumbs);
    const newEvent = event;
    newEvent.breadcrumbs = newBreadcrumbs;
    return newEvent;
};
export const removeAuthHeader = (event) => {
    var _a;
    const newEvent = event;
    if ((_a = newEvent.request) === null || _a === void 0 ? void 0 : _a.headers) {
        delete newEvent.request.headers.Authorization;
    }
    return newEvent;
};
const keysToScrub = [
    'email',
    'username',
    'password',
    'customerEmail',
    'customerFirstName',
    'customerLastName',
    'customerFamilyName',
    'customerPhone',
    'customerAddressZip',
    'customerAddressCity',
    'customerAddressStreet',
    'customerAddressNumber',
    'title',
    'longitude',
    'latitude',
    'billingNameFirst',
    'billingAddressZip',
    'billingAddressCity',
    'billingVatNumber',
    'companyAddressZip',
    'companyAddressCity',
    'companyAddressStreet',
    'companyAddressNumber',
    'firstName',
    'familyName',
    'privatePhone',
    'name',
    'phone',
];
export const scrubEvent = composeFuncs(limitBreadCrumbs, removeAuthHeader, (event) => scrubObject(event, keysToScrub));
