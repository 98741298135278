import { decipher, } from './cipher.js';
export function decipherTokenIfNeeded(token) {
    let actualToken = token;
    let companyId;
    if (token.substr(0, 1) === '+') {
        const decipherFunc = decipher(process.env.REACT_APP_COMPANY_ID_CIPHER || '07b03a43fce2bbb506ae8ce970e3bbf2');
        const decipheredString = decipherFunc(token.substr(1));
        const splitStrings = decipheredString.split(' ');
        companyId = splitStrings[0];
        actualToken = splitStrings[1] || '';
    }
    return [actualToken, companyId];
}
