export const zipCodePattern = /^(([0-9][1-9][0-9]{3})|([1-9][0-9]{4}))$/;
export const isZipValid = (zipCode) => !!zipCode.match(zipCodePattern);
export const multipleZipCodeIdPattern = /^((([0-9][1-9][0-9]{3})|([1-9][0-9]{4}))[   \r\n]*,[   \r\n]*)*(([0-9][1-9][0-9]{3})|([1-9][0-9]{4}))$/;
export const isZipExpired = (zip) => {
    const today = new Date(Date.now());
    const expired = (zip.validTo && today > new Date(zip.validTo));
    return expired;
};
export const isZipNotYetActive = (zip) => {
    const today = new Date(Date.now());
    const notYetActive = (zip.validFrom && new Date(zip.validFrom) > today);
    return notYetActive;
};
export const isZipActive = (zip) => !(isZipNotYetActive(zip) || isZipExpired(zip));
export const parseZipCodeArray = (zipCodes) => {
    if (!zipCodes)
        return [];
    const cleanZipCodes = zipCodes.replace(/[ \s\r\n]*,[ \s\r\n]*/gi, ',');
    const zipArray = cleanZipCodes.split(',');
    return zipArray;
};
export const isZipCodeArraySyntaxValid = (zipCodes) => {
    const zipArray = parseZipCodeArray(zipCodes);
    if (!zipCodes || zipCodes.length === 0)
        return false;
    const foundInvalid = zipArray.find(zip => !zip || !isZipValid(zip));
    return foundInvalid === undefined;
};
const separator = ', ';
export const stripZipCodes = (zips) => {
    var _a;
    return ((_a = zips === null || zips === void 0 ? void 0 : zips.replace(/[^,\d]+/g, '')) === null || _a === void 0 ? void 0 : _a.replace(/,/g, separator)) || '';
};
export const splitZipCodes = (zips) => { var _a; return ((_a = stripZipCodes(zips)) === null || _a === void 0 ? void 0 : _a.split(separator)) || []; };
export function getInactiveZipName(name) {
    return name ? `[ -- ${name}]` : name;
}
const inactiveMatcher = /\[\s*--\s*(\d{5})\s*\]/gi;
export function hasInactiveZipName(zipCodes) {
    return !!(zipCodes === null || zipCodes === void 0 ? void 0 : zipCodes.match(inactiveMatcher));
}
export function activateZipNames(zipCodes) {
    return zipCodes === null || zipCodes === void 0 ? void 0 : zipCodes.replace(inactiveMatcher, '$1');
}
