import { getCustomerRoutes } from '../networking/routes.js';
import { ProductTypes } from '../types/Case.js';
export const allTacItems = [
    'acceptTAC', 'acceptWithdrawal', 'acceptPrivacyPolicy', 'confirmAuthorization',
];
export const tacItems = {
    [ProductTypes.STANDARD]: ['acceptTAC', 'acceptWithdrawal', 'acceptPrivacyPolicy', 'confirmAuthorization'],
    [ProductTypes.PREMIUM]: ['acceptPrivacyPolicy', 'confirmAuthorization'],
    [ProductTypes.INSURANCE]: ['confirmAuthorization'],
};
export const privacyLinks = {
    [ProductTypes.STANDARD]: `${getCustomerRoutes().staticAsset.pdfRoot}/ADAC_Schluesselnotdienst_Datenschutz_PDF_PUB_03_24.pdf`,
    [ProductTypes.PREMIUM]: 'https://www.adac.de/datenschutz/versicherungen/',
    [ProductTypes.INSURANCE]: undefined,
};
