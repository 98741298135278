export var UserRole;
(function (UserRole) {
    UserRole["MANAGER"] = "manager";
    UserRole["COMPANY"] = "company";
    UserRole["DISPATCHER"] = "dispatcher";
    UserRole["API"] = "api";
    UserRole["SYS"] = "sys";
})(UserRole = UserRole || (UserRole = {}));
export const hasUserRole = (role, match = UserRole.SYS) => role === match || (typeof role === 'object' && role.length === 1 && role.includes(match));
export const isUserRoleReadOnly = hasUserRole;
export const UserRoleListBackoffice = [
    UserRole.MANAGER,
    UserRole.API,
    UserRole.SYS,
];
export const UserRoleListCompany = [
    UserRole.COMPANY,
    UserRole.DISPATCHER,
];
