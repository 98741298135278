import camelcase from 'camelcase';
export var CaseActions;
(function (CaseActions) {
    CaseActions["SND_ACCEPT"] = "120";
    CaseActions["SND_REJECT"] = "130";
    CaseActions["SEND_MESSAGE"] = "170";
    CaseActions["SELECT_SND"] = "200";
    CaseActions["UPDATE_CASE"] = "201";
    CaseActions["DELETE_CASE"] = "202";
    CaseActions["HUBBLE_COMMISSIONING_EXPIRES"] = "203";
    CaseActions["HUBBLE_COMMISSIONING_UPDATE"] = "205";
    CaseActions["HUBBLE_FEEDBACK"] = "206";
    CaseActions["SELECT_DRIVER"] = "210";
    CaseActions["HERMES_CONFIRM_COMMISSION"] = "211";
    CaseActions["CLICK_DISPATCH_LINK"] = "215";
    CaseActions["DRIVER_SHARE_LOCATION"] = "220";
    CaseActions["DRIVER_ARRIVES"] = "230";
    CaseActions["CALL_CUSTOMER"] = "240";
    CaseActions["COMISSION_OPENING"] = "300";
    CaseActions["REJECT_OPENING"] = "305";
    CaseActions["CREATE_OPENING_REPORT"] = "301";
    CaseActions["CREATE_EXTRA_DAMAGE_REPORT"] = "302";
    CaseActions["COMISSION_EXTRA_COST"] = "310";
    CaseActions["COMISSION_EXTRA_DAMAGE"] = "320";
    CaseActions["EDIT_EXTRA_DAMAGE"] = "321";
    CaseActions["GIVE_UP_COMISSION"] = "325";
    CaseActions["COMPLETE_DOOR_OPENING"] = "329";
    CaseActions["CREATE_FINAL_REPORT"] = "330";
    CaseActions["REFUSE_FINAL_REPORT_SIGNATURE"] = "340";
    CaseActions["CLOSE_CASE_MANUAL"] = "801";
    CaseActions["UPDATE_IMAGES_MANUAL"] = "810";
    CaseActions["REGENERATE_REPORTS"] = "820";
    CaseActions["UPLOAD_INVOICE"] = "500";
    CaseActions["SEND_INVOICE_EMAIL"] = "510";
    CaseActions["SEND_EMAIL_CUSTOMER"] = "950";
    CaseActions["CONFIRMATION_SEND_EMAIL"] = "951";
    CaseActions["SEND_SURVEY"] = "1000";
    CaseActions["SEND_INVOICE_REMINDER_SMS"] = "1200";
})(CaseActions = CaseActions || (CaseActions = {}));
export function getCaseActionName(type, camelCase = false, defaultType = `caseAction:${type}`) {
    var _a;
    const name = ((_a = Object.entries(CaseActions).find(a => a[1] === type)) === null || _a === void 0 ? void 0 : _a[0]) || defaultType;
    return camelCase ? camelcase(name) : name;
}
export function isCaseStatusActionCode(action) {
    return !!action && !!getCaseActionName(action, undefined, '');
}
export var CaseStatusList;
(function (CaseStatusList) {
    CaseStatusList["START"] = "initialHubbleCommissioning";
    CaseStatusList["SND_SELECTED"] = "sndSelected";
    CaseStatusList["DRIVER_SELECTED"] = "driverSelected";
    CaseStatusList["COMMISSION_CONFIRMED"] = "commissionConfirmed";
    CaseStatusList["DRIVER_APPROACHING"] = "driverApproaching";
    CaseStatusList["DRIVER_ARRIVED"] = "driverArrived";
    CaseStatusList["OPENING_STARTED"] = "openingStarted";
    CaseStatusList["OPENING_COMPLETED"] = "openingCompleted";
    CaseStatusList["EXTRA_DAMAGE_ONGOING"] = "extraDamageOngoing";
    CaseStatusList["EDITING_EXTRA_DAMAGE"] = "editingExtraDamage";
    CaseStatusList["CASE_COMPLETED"] = "caseCompleted";
    CaseStatusList["IMAGES_UPDATED"] = "imagesUpdated";
})(CaseStatusList = CaseStatusList || (CaseStatusList = {}));
export function isCaseStatusEvent(event) {
    return !!event
        && Object.values(CaseStatusList).findIndex(e => e === event) >= 0;
}
export function validateEventName(event) {
    const isRealState = isCaseStatusEvent(event);
    return {
        valid: isRealState,
        name: isRealState ? event : getCaseActionName(event),
    };
}
export const allowedStatesCaseStartForPricing = [
    CaseStatusList.START,
    CaseStatusList.SND_SELECTED,
].map(s => `${s}`);
export const allowedStatesForClosing = [
    CaseStatusList.SND_SELECTED,
    CaseStatusList.DRIVER_SELECTED,
    CaseStatusList.DRIVER_APPROACHING,
    CaseStatusList.COMMISSION_CONFIRMED,
    CaseStatusList.DRIVER_ARRIVED,
    CaseStatusList.OPENING_STARTED,
    CaseStatusList.OPENING_COMPLETED,
    CaseStatusList.EDITING_EXTRA_DAMAGE,
    CaseStatusList.EXTRA_DAMAGE_ONGOING,
].map(s => `${s}`);
export const filesNotAvailableStates = [
    CaseStatusList.SND_SELECTED,
    CaseStatusList.DRIVER_SELECTED,
    CaseStatusList.COMMISSION_CONFIRMED,
    CaseStatusList.DRIVER_APPROACHING,
    CaseStatusList.DRIVER_ARRIVED,
].map(s => `${s}`);
export const openCasesStates = [
    CaseStatusList.SND_SELECTED,
    CaseStatusList.DRIVER_SELECTED,
    CaseStatusList.COMMISSION_CONFIRMED,
    CaseStatusList.DRIVER_APPROACHING,
    CaseStatusList.DRIVER_ARRIVED,
    CaseStatusList.OPENING_STARTED,
    CaseStatusList.OPENING_COMPLETED,
    CaseStatusList.EXTRA_DAMAGE_ONGOING,
    CaseStatusList.EDITING_EXTRA_DAMAGE,
].map(s => `${s}`);
export const closedCasesStates = [
    CaseStatusList.CASE_COMPLETED,
    CaseStatusList.IMAGES_UPDATED,
].map(s => `${s}`);
export const draftCasesStates = [].map(s => `${s}`);
export const viewFinalReportStates = [
    CaseStatusList.OPENING_COMPLETED,
    CaseStatusList.CASE_COMPLETED,
    CaseStatusList.IMAGES_UPDATED,
].map(s => `${s}`);
export const cancelledCaseActions = [
    +CaseActions.DELETE_CASE,
    +CaseActions.HUBBLE_COMMISSIONING_UPDATE,
];
export const isCaseCancelledByHermes = (caseAction) => cancelledCaseActions.includes(+(caseAction || -1));
export const isCaseWithdrawnByHermes = (caseAction) => +CaseActions.HUBBLE_COMMISSIONING_UPDATE === (+(caseAction || -1));
export const notActionedOrRejectedCaseActions = [
    +CaseActions.HUBBLE_COMMISSIONING_EXPIRES,
    +CaseActions.SND_REJECT,
];
export const isCaseRejectedOrIgnoredByPartner = (caseAction) => notActionedOrRejectedCaseActions.includes(+(caseAction || -1));
export const isCaseCancelled = (caseAction) => isCaseCancelledByHermes(caseAction) || isCaseRejectedOrIgnoredByPartner(caseAction);
export const isCaseClosedByBackoffice = (caseAction) => +CaseActions.CLOSE_CASE_MANUAL === +(caseAction || -1);
export const isCaseCompleted = (caseAction) => +CaseActions.CREATE_FINAL_REPORT === +(caseAction || -1)
    || +CaseActions.REGENERATE_REPORTS === +(caseAction || -1);
